<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    avatarUrl?: string,
    width?: number,
  }>(),
  {
    width: () => 40
  }
)

const status = ref<'loading' | 'error' | 'success' | 'none'>('none')

const computedAvatarUrl = computed(() => {
  return props?.avatarUrl?.replace('https://renjuportal.com/avatar/', 'https://v1.renjuportal.com/avatar/')
})

const computedColor = computed(() => {
  switch (status.value) {
    case 'loading':
    case 'error':
    case 'none' :
      return 'secondary-light'
    case 'success':
    default:
      return 'transparent'
  }
})

watch(computedAvatarUrl, () => {
  if (computedAvatarUrl) {
    status.value = 'loading'
  } else {
    status.value = 'none'
  }
})
</script>

<template>
  <v-avatar :color="computedColor" :style="{ width: `${props.width}px`, height: `${props.width}px` }">
    <v-img
      :src="computedAvatarUrl"
      class="ma-0"
      @error="status = 'error'"
      @load="status = 'success'"
    />
  </v-avatar>
</template>

<style scoped lang="scss">
.avatar {
  &__icon {
    width: 66.6%;
    height: 66.6%;
    opacity: 0.4;
  }
}
</style>
